import React from "react";
import style from "./index.module.css";
import GoBack from "../../../modules/GoBack";
import flower from "../../../assets/images/main/product/flower-right.png";

export default function NewsItem() {
  return (
    <div>
        <img className={style.flower_right} src={flower} alt="flower_right" />
        <div className="container">
          <GoBack />
          <h1 className={style.title}>Блог</h1>
          <p className={style.description}>
            На данной странице вы можете ознакомиться с дополнительной информации
            о проекте. Прочитать статьи на экологическую тематику или узнать
            о ближайших мероприятиях, которые вы сможете посетить.
          </p>
        </div>
    </div>
  );
}
