import React from "react";

import Banner from '../components/MainWrapper/Banner';
import Team from '../components/MainWrapper/Team';
import ProductsMain from '../components/MainWrapper/ProductsMain';
import Mentorship from '../components/MainWrapper/Mentorship';
import News from '../components/MainWrapper/News';
import Events from "../components/MainWrapper/Events";
import ScrollNavMain from '../components/MainWrapper/ScrollNavMain'

const MainPage = () => {
  return (
    <>
      <Banner />
      <Team />
      <ProductsMain />
      <Mentorship />
      <News />
      <Events />
      <ScrollNavMain />
    </>
  );
};

export default MainPage;
