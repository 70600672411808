import React from 'react'

import GoBack from '../../modules/GoBack'
import CatalogTitle from './CatalogTitle'
import CatalogFilter from './CatalogFilter'
import CatalogList from './CatalogList'


const CatalogWrapper = () => {
    return (
    <>
        <GoBack />
        <CatalogTitle />
        <CatalogFilter />
        <CatalogList />
    </>
  )
}

export default CatalogWrapper