import React from 'react'
import { Link } from 'react-router-dom'

import mentors from '../../../assets/images/main/mentorship/mentors.png'
import branch from '../../../assets/images/main/mentorship/branch.png'

import style from './index.module.css'

const Mentorship = () => {
  return (
    <section className={style.mentorship} id='main_mentorship'>
      <img className={style.img_branch} src={branch} alt="/" />
      <div className="container">
        <div className={style.mentorship_block}>
          <div className={style.mentorship_block__text}>
            <h2>НАСТАВНИЧЕСТВО И ПРОФОРИЕНТАЦИЯ</h2>
            <div className={style.mentorship_block__item}>
              <p>Любое начинание в профессии, возможно, пугает: где, как, что важно?‥</p>
              <p>И вот именно в такой момент помогает консультация с опытным, профессиональным специалистом.</p>
              <p>Если у вас возникает вопрос о профессии Эколога, теме научных исследований, актуальности получаемых знаний и их практического применения, то нужно и можно обратиться за советом к профессионалам.</p>
            </div>            
          </div>
          <div className={style.item_img}>
            <img src={mentors} alt="/" />
          </div>
        </div>
        <div className={style.mentorship_btn}>
          <Link  to="mentor" className="btn">
            Выбрать наставника
          </Link> 
        </div>
      </div>
    </section>
  )
}

export default Mentorship