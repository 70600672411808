import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductCardWrapper from "../components/ProductCardWrapper/index.jsx";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";
import GoBack from "../modules/GoBack";

const ProductPage = () => {
  const [game, setGame] = useState(null);
  const params = useParams();
  useEffect(() => {
    const fetchGame = async () => {
      const { data } = await axios.get(`/games/${params.id}`);
      setGame(data);
    };

    fetchGame();
  }, [params.id]);
  return (
    <div>
      {/* <Breadcrumbs pageName={params.title} />
      <GoBack /> */}
      {game ? <ProductCardWrapper game={game} /> : <p>Данные недоступны.</p>}
    </div>
  );
};
export default ProductPage;
