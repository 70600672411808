import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  blogs: [],
  loading: false,
};

// функция, отвечающая за получение всех статей
export const getAllBlogs = createAsyncThunk("blog/getAllBlogs", async () => {
  try {
    const { data } = await axios.get("/blogs");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // получение всех статей
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload.blogs;
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default blogSlice.reducer;
