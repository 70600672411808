import React from "react";
import style from "./index.module.css";
import PhotoSlider from "../../TeamWrapper/PhotoSlider";

export default function Gallary() {
  return (
    <div className={style.main}>
      <div className="container" >
        <p className={style.title}>ГАЛЕРЕЯ</p>
      </div>
      <PhotoSlider />
    </div>    
  );
}
