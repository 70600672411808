import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Slider from "react-slider";

import { ReactComponent as IconFilter } from "../../../assets/images/main/product/icon-filter.svg";
import { ReactComponent as IconMark } from "../../../assets/images/main/product/icon-mark.svg";
import mill_filter from "../../../assets/images/main/product/mill-filter.png";
import flower from "../../../assets/images/main/product/flower-right.png";

import formatterPrice from "../../../utils/FormatterPrice";

import style from "./index.module.css";
import "./rslider.css";

import { useDispatch } from "react-redux";
import { setGameParams, fetchGames } from "../../../redux/game/gameSlice";

// Рандомные значения
const MIN = 3000;
const MAX = 100000;

const CatalogFilter = () => {
  const formatItems = [
    "Настольная",
    "Настольно-интерактивная",
    "Командная",
    "Коммуникативная",
    "Online",
  ];
  const difficultyItems = ["Легкая", "Сложная", "Смешанная"];

  const { register, handleSubmit, reset, control } = useForm();
  const [values, setValues] = useState([MIN, MAX]);

  // Сбрасываем все значения фильтра
  const handleResetFilter = () => {
    reset();
    setValues([MIN, MAX]);

    dispatch(setGameParams(MIN, MAX, [], []));
    dispatch(fetchGames());
  };

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const [minPrice, maxPrice] = values;
    const { format, difficulty } = data;
    // функция, устанавливает параметры фильтрации игр
    dispatch(setGameParams(minPrice, maxPrice, format, difficulty));
    // функция, отправляет действия для получения списка игр
    dispatch(fetchGames());
  };

  // Кнопка фильтр (open)
  const [visible, setVisibility] = useState(false);
  const filterOpenClick = () => {
    setVisibility((currentValue) => !currentValue);
  };

  return (
    <div className={style.filters}>
      <img className={style.flower_right} src={flower} alt="flower_right" />
      <div className="container">
        <div className={style.filters_wrapper}>
          <button className={style.filter_btn} onClick={filterOpenClick}>
            <IconFilter />
            <span>Фильтр</span>
          </button>
          {visible && (
            <div className={style.filters_block}>
              <img src={mill_filter} alt="изображение мельницы" />

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={style.filters_list}
              >
                <div className={style.filters_item}>
                  <h3>Цена</h3>
                  <Controller
                    control={control}
                    name="Slider"
                    render={({ field: { onChange, value, min, max } }) => (
                      <Slider
                        className={"slider"}
                        onChange={setValues}
                        value={values}
                        min={MIN}
                        max={MAX}
                      />
                    )}
                  />
                  <div className={style.filters_price}>
                    <span className={style.filters_price__item}>
                      от {formatterPrice.format(values[0])}
                    </span>
                    <span className={style.filters_price__item}>
                      до {formatterPrice.format(values[1])}
                    </span>
                  </div>
                </div>

                <div className={style.filters_item}>
                  <h3>Формат игры</h3>
                  <div className={style.filters_game__input}>
                    {formatItems.map((formatItem) => (
                      <label key={formatItem}>
                        <input
                          type="checkbox"
                          value={formatItem}
                          {...register("format")}
                        />
                        <IconMark className={style.filter_checkbox} />
                        {formatItem}
                      </label>
                    ))}
                  </div>
                </div>

                <div className={style.filters_item}>
                  <h3>Уровень сложности</h3>
                  <div className={style.filters_difficulty__input}>
                    {difficultyItems.map((difficultyItem) => (
                      <label key={difficultyItem}>
                        <input
                          type="checkbox"
                          value={difficultyItem}
                          {...register("difficulty")}
                        />
                        <IconMark className={style.filter_checkbox} />
                        {difficultyItem}
                      </label>
                    ))}
                  </div>
                  <div className={style.filter_btn__list}>
                    <input
                      className={style.filter_btn__item}
                      type="submit"
                      value="Применить"
                    />
                    <input
                      className={style.filter_btn__item}
                      type="reset"
                      value="Сбросить"
                      onClick={handleResetFilter}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatalogFilter;
