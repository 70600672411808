import React from "react";
import style from "./index.module.css";
import list from "../../../assets/images/team/list.svg";

const Organization = () => {
  return (
    <div className={style.main} id='team_organization'>
      <div className="container">
        <h2 className={style.title}>Организация и проведение деловых игр</h2>
        <div className={style.roster}>
          <img className={style.list} src={list} alt="list" />
          <div className={style.item}>
            <div className={style.paragraph}>
              <p>
                Есть наработанные варианты игровых форматов, уже опробированных
                в аудиториях.
              </p>
              <span>1</span>
            </div>
            <div className={style.paragraph}>
              <p>Можно игру сформировать под ваш личный запрос.</p>
              <span>2</span>
            </div>
            <div className={style.paragraph}>
              <p>Можете выбрать любой формат игр.</p>
              <span>3</span>
            </div>
            <div className={style.paragraph}>
              <p>
                При написание игровых вопросов учитывается уровень
                подготовленности и тематической заинтересованности аудитории.
              </p>
              <span>4</span>
            </div>
            <div className={style.paragraph}>
              <p>
                Если вы выбираете игры с нашим ведущим, заранее обговаривается
                на какой площадке будет проведена игра, какое техническое
                обеспечение необходимо, призовой фонд, а также прописывается
                сценарий.
              </p>
              <span>5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
