import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import style from "./index.module.css";

const PageWrapper = () => {
  return (
    <>
      <div className={style.main}>
        <Header />
        <div className={style.item}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PageWrapper;
