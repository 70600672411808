import React, { useState } from "react";
import Carousel from "react-multi-carousel";

import ModalWindow from "../MentorWrapper/ModalWindow";

import flower from "../../assets/images/main/product/flower-right.png";
import branch from "../../assets/images/main/mentorship/branch.png";
import Arrow_left from "../../assets/images/main/product/arrow-left.svg";
import Arrow_right from "../../assets/images/main/product/arrow-right.svg";

import style from "./index.module.css";
import "./carouselCard.css";
import OrderWindow from "../CatalogWrapper/CatalogList/OrderWindow";
import Breadcrumbs from "../Breadcrumbs";
import GoBack from "../../modules/GoBack";
import formatterPrice from "../../utils/FormatterPrice";

export default function ProductCard({ game }) {
  const imageArray = () => {
    const imageArray = game.imgIds.filter((id) => id !== game.previewImg);
    imageArray.unshift(game.previewImg);
    return imageArray;
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 991 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 991, min: 744 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 744, min: 360 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 360, min: 0 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        aria-label="Go to previous slide"
        className={style.arrow_left}
        type="button"
        onClick={onClick}
      >
        {" "}
        <img src={Arrow_left} alt="Arrow_left" />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        aria-label="Go to next slide"
        className={style.arrow_right}
        type="button"
        onClick={onClick}
      >
        {" "}
        <img src={Arrow_right} alt="Arrow_right" />
      </button>
    );
  };

  const [showWindow, setShowWindow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeWindow = () => {
    setShowWindow(false);
  };

  const openWindow = () => {
    setShowWindow(true);
  };

  return (
    <div className={style.main}>
      <img className={style.flower_right} src={flower} alt="flower_right" />
      <img className={style.img_branch} src={branch} alt="branch" />
      <section className={`${style.productCardWrapper}`}>
        <div className={style.breadcrumbs_text}>
          <Breadcrumbs pageName={game.title} />
        </div>
        <div className={style.back_text}>
          <GoBack />
        </div>
        <div className={`container ${style.test}`}>
          <h1 className={style.title}>{game.title}</h1>
          <div className={style.card_block}>
            <div className={style.card_carousel}>
              <div className={style.app_carousel}>
              <Carousel
                className={style.carousel}
                showDots={true}
                responsive={responsive}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
              >
                {imageArray().map((imgId) => (
                  <div className={style.carousel_photo} key={imgId}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${imgId}`}
                      alt="carousel-img"
                      className={style.photo}
                    />
                  </div>
                ))}
              </Carousel>
              </div>
            </div>
            <div className={style.card_info}>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Аудитория</span>
                <p className={style.card_text_p}>{game.purpose}</p>
              </div>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Уровень сложности:</span>
                <p className={style.card_text_p}>{game.difficulty}</p>
              </div>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Формат игры:</span>
                <p className={style.card_text_p}>{game.format}</p>
              </div>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Темы вопросов</span>
                <p className={style.card_text_p}>{game.theme}</p>
              </div>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Состав игры:</span>
                <p className={style.card_text_p}>{game.complectation}</p>
              </div>
              <div className={style.card_text}>
                <span className={style.card_text_span}>Описание игры</span>
                <p className={style.card_text_p}>{game.description}</p>
              </div>
              <div className={style.card_price}>
                <span className={style.card_price_span}>
                  {formatterPrice.format(game.price)}
                </span>
              </div>
              <button
                className={style.orderBtn}
                onClick={openWindow}
              >
                Заказать
              </button>

              <OrderWindow
                showWindow={showWindow}
                closeWindow={closeWindow}
                setShowModal={setShowModal}
                fromWhere={game.title}
              />

              <ModalWindow
                showModal={showModal}
                closeModal={() => setShowModal(false)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
