import React, { useState } from "react";
import style from "./index.module.css";
import Breadcrumbs from "../../Breadcrumbs";
import flower from "../../../assets/images/main/product/flower-right.png";
import flower_green from "../../../assets/images/mentors/flower_green.png";
import ConectWindow from "../ConectWindow";
import ModalWindow from "../../MentorWrapper/ModalWindow";
import GoBack from "../../../modules/GoBack";

export default function MentorCardPage({ mentor }) {
  const [showWindow, setShowWindow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeWindow = () => {
    setShowWindow(false);
  };

  const openWindow = () => {
    setShowWindow(true);
  };

  return (
    <div className={style.main}>
      <div className="container">
        <img className={style.flower_right} src={flower} alt="flower_right" />
        <img
          className={style.flower_green}
          src={flower_green}
          alt="flower_green"
        />
        <div>
          <Breadcrumbs pageName={mentor.fullName} />
          <GoBack />
          <h1 className={style.fullName}>{mentor.fullName}</h1>
          <div className={style.main}>
            <img
              className={style.photo}
              src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${mentor.previewImg}`}
              alt="img"
            />
            <div>
              <div className={style.description}>
                <p className={style.role}>
                  {mentor.role === "Другое" ? mentor.otherRole : mentor.role}
                </p>
                <p className={style.content}>{mentor.jobTitle}</p>
                <div className={style.chapter}>
                  <p className={style.title}>Направления:</p>
                  <p className={style.content}>{mentor.direction}</p>
                </div>
                <div className={style.chapter}>
                  <p className={style.title}>Стаж:</p>
                  <p className={style.content}>{mentor.experience}</p>
                </div>
                <div className={style.chapter}>
                  <p className={style.title}>Образование:</p>
                  <p className={style.content}>{mentor.education}</p>
                </div>
                <div className={style.chapter}>
                  <p className={style.title}>Трудовая деятельность:</p>
                  <p className={style.content}>{mentor.laborActivity}</p>
                </div>
              </div>
              {mentor.role !== "Другое" && (
                <button
                  onClick={openWindow}
                  className={style.button}
                >
                  Связаться с наставником
                </button>
              )}
              <ConectWindow
                showWindow={showWindow}
                closeWindow={closeWindow}
                setShowModal={setShowModal}
                fromWhere={mentor.fullName}
              />
            </div>
            <ModalWindow
              showModal={showModal}
              closeModal={() => setShowModal(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
