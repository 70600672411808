import React from 'react'
// import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom'



const MenuItem = () => {
  const menuitems = [
    {
      id: 1,
      title: 'О проекте',
      link: '/team',
    },
    {
      id: 2,
      title: 'Продукция',
      link: '/catalog',
    },
    {
      id: 3,
      title: 'Наставничество',
      link: '/mentor',
    },
    {
      id: 4,
      title: 'Блог',
      link: '/news',
    },
  ]

  return (
    <>
      
      {menuitems.map((item) => (
        <li key={item.id}>
          <NavLink to={item.link}>{item.title}</NavLink>
        </li>
      ))}      
      
    </>
  )
}

export default MenuItem