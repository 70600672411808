import React from "react";

import CatalogWrapper from '../components/CatalogWrapper'
import Breadcrumbs from "../components/Breadcrumbs";


const CatalogPage = () => {
  return (
    <>
       <Breadcrumbs />
       <CatalogWrapper/>
    </>
  )
}

export default CatalogPage;
