import React from 'react'

import CatalogTitle from '../../CatalogWrapper/CatalogTitle'
import ProductsBanner from './ProductsBanner'
import ProductCarousel from './ProductCarousel'

import style from './index.module.css'

const ProductsMain = () => {
  return (
    <div className={style.products} id='main_products'>
        <CatalogTitle />
        <ProductsBanner />
        <ProductCarousel />
    </div>
  )
}

export default ProductsMain