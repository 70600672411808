import React from "react";
import WhyRootsCrown from "../components/TeamWrapper/WhyRootsCrown"
import WhyBruneco from "../components/TeamWrapper/WhyBruneco";
import Relevance from "../components/TeamWrapper/Relevance";
import Goals from "../components/TeamWrapper/Goals";
import Team from "../components/TeamWrapper/Team";
import CommandList from "../components/TeamWrapper/CommandList";
import Organization from "../components/TeamWrapper/Organization";
import PhotoSlider from "../components/TeamWrapper/PhotoSlider";
import BusinessGames from "../components/TeamWrapper/BusinessGames";
import Achievements from "../components/TeamWrapper/Achievements";
import Diplomas from "../components/TeamWrapper/Diplomas";
import ScrollNavTeam from '../components/TeamWrapper/ScrollNavTeam'

const TeamPage = () => {
  return (
    <div>
      <WhyRootsCrown />
      <WhyBruneco />
      <Relevance />
      <Goals />
      <Team />
      <CommandList />
      <Organization />
      <PhotoSlider />
      <BusinessGames />
      <Achievements />
      <Diplomas />
      <ScrollNavTeam />
    </div>
  );
};

export default TeamPage;
