import React, { useEffect } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getAllGames } from '../../../../redux/game/gameSlice'
import CatalogItem from '../../../CatalogWrapper/CatalogList/CatalogItem'


import style from './index.module.css'
import './customCaruosel.css'


const ProductCarousel = () => {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.game.games);

  useEffect(() => {
    try {
    
      dispatch(getAllGames())
        
    } catch (error) {
      console.log(error);
    }
    
  }, [dispatch]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 991 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 991, min: 670 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 670, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  const product = games.map(game => (
    <CatalogItem key={game.id} game={game} />
  ))

  return (
      <div className='container'>
        {/* {isLoading? <Loader /> : */}
        <div className={style.carousel_block}>
          <Carousel 
            containerClass="containers"
            responsive={responsive}
            ssr={true} 
            infinite={true}
            keyBoardControl={true}
          >
            {product}
          </Carousel>
        </div>
        <Link to='/catalog' className={style.text_link}>
          Перейти в каталог
        </Link>
      </div>    
  )
}

export default ProductCarousel