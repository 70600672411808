import React, { useEffect } from "react";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../../redux/blog/blogSlice";
import BlogCard from "./BlogCard";

export default function Blog() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog.blogs);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <div className={`${style.main} container`}>

      <div className={style.news}>
        {blogs?.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
}
