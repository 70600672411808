import React, { useEffect, useState } from "react";
import MentorCardPage from "../components/MentorCardWrapper/MentorCardPage";
import { useParams } from "react-router-dom";
import axios from "../utils/axios"

const MentorSinglePage = () => {
  const [mentor, setMentor] = useState(null);
  const params = useParams();

 
  useEffect(() => {
    const fetchMentor = async () => {
      const { data } = await axios.get(`/mentors/${params.id}`);
      setMentor(data);
    };
  
    fetchMentor();
  }, [params.id]);


  return (
      <div>
        { mentor && <MentorCardPage mentor={mentor}/> }
      </div>
  );
};

export { MentorSinglePage };
