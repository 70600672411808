import React from 'react'
import { Link } from 'react-router-dom'
import style from './index.module.css'

import logo from '../../assets/images/logo.svg'


const Logo = () => {
  return (
    <>
      <Link to="/" className={style.logo_image}>
        <img src={logo} alt="/" />
        <span>БРУНЭКО</span>
      </Link> 
    </>
  )
}

export default Logo