import React from 'react';
import { Link } from 'react-scroll';

import style from './ScrollNavigalion.module.css';


const ScrollNavigalion = ({ navItems, spy, smooth, duration }) => {
  return (
    <div className={style.main_scroll_nav}>
      <ul>
        {navItems.map(({ id, label, offset }) => (
          <li key={id} className={style.list_item}>
            <Link
              className={style.link_item}
              to={id}
              spy={spy}
              smooth={smooth}
              offset={offset}
              duration={duration}
            >
              <div className={style.tooltip}>{label}</div>
              <div className={style.dot}></div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScrollNavigalion;