import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAllGames } from '../../../redux/game/gameSlice'
import CatalogItem from './CatalogItem'

import style from './index.module.css'


const CatalogList = () => {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.game.games);

  useEffect(() => {
    dispatch(getAllGames());
  }, [dispatch]);


  // Проверка, если игры отсутствуют выводится сообщение
  if (!games || games.length === 0) {
    return <p className='container'>Игры отсутствуют.</p>;
  }

  return ( 
    <div className={style.main}>
      <div className='container'>
        <div className={style.catalog_list}>
          {games?.map((game) => (
            <CatalogItem key={game.id} game={game} />
          ))}
        </div>
      </div>
    </div>  
  )
}

export default CatalogList