import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  mentors: [],
  loading: false,
};

// функция, отвечающая за получение всех менторов
export const getAllMentors = createAsyncThunk(
  "mentor/getAllMentors",
  async () => {
    try {
      const { data } = await axios.get("/mentors");
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const mentorSlice = createSlice({
  name: "mentor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // получение всех менторов
      .addCase(getAllMentors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMentors.fulfilled, (state, action) => {
        state.loading = false;
        state.mentors = action.payload.mentors;
      })
      .addCase(getAllMentors.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default mentorSlice.reducer;
