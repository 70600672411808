import React from 'react'
import ScrollNavigalion from '../../common/ScrollNavigation'

const navItems = [
    { id: 'team_roots__crown', label: 'Почему от Корней к Кроне?', offset: 70 },
    { id: 'team_bruneco', label: 'Почему Брунеко?', offset: -10 },
    { id: 'team_relevance', label: 'Актуальность профориентации', offset: -10 },
    { id: 'team_goals', label: 'Цели задачи проекта', offset: 40 },
    { id: 'team_team', label: 'Команда проекта', offset: 60 },
    { id: 'team_organization', label: 'Организация деловых игр', offset: 30 },
    { id: 'team_business__games', label: 'Деловые игры', offset: 80 },
    { id: 'team_achievements', label: 'Наши достижения', offset: 40 },
  ];

const ScrollNavTeam = () => {

    const spy = true;
    const smooth = true;
    const duration = 500;

  return (
    <ScrollNavigalion navItems={navItems} spy={spy} smooth={smooth} duration={duration} />
  )
}

export default ScrollNavTeam