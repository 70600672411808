import React, { useEffect } from "react";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllMentors } from "../../../redux/mentor/mentorSlice";
import MentorCard from "../../MentorWrapper/MentorCard";
import { Link } from "react-router-dom";
import arrow from "../../../assets/images/team/arrow.svg";

const CommandList = () => {
  const dispatch = useDispatch();
  const mentors = useSelector((state) => state.mentor.mentors);

  useEffect(() => {
    dispatch(getAllMentors());
  }, [dispatch]);

  return (
    <div className={style.main}>
      <div className="container">
        <p className={style.title}>Наша команда</p>

        <div className={style.mentor_cards}>
          {mentors?.map((mentor) => (
            <MentorCard key={mentor.id} mentor={mentor} />
          ))}
        </div>

        <Link className={style.link} to="/mentor">О наставничестве и профориентации</Link>
        <img className={style.arrow} src={arrow} alt="arrow" />

      </div>
    </div>
  );
};

export default CommandList;
