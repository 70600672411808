import React, { useState } from "react";
import { Link } from 'react-router-dom'
import style from "./index.module.css";
import { useForm } from "react-hook-form";
import axios from "../../../utils/axiosGoogle";
import flower_blue from "../../../assets/images/mentors/flower_blue.png";
import flower_green from "../../../assets/images/mentors/flower_green.png";
import ModalWindow from "../ModalWindow";

export default function FeedbackForm() {
  const [showModal, setShowModal] = useState(false);
  const [counters, setCounters] = useState({
    Comment: 0,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      Name: undefined,
      Phone: undefined,
      Email: undefined,
      Comment: undefined,
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("Name", data.Name);
    formData.append("Phone", data.Phone);
    formData.append("Email", data.Email);
    formData.append("Comment", data.Comment);
    formData.append("Date", new Date().toLocaleString("ru"));
    setShowModal(true);
    reset();
    setCounters({
      Comment: 0,
    });
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await axios.post(
        "/macros/s/AKfycby0icChW5_cC9bKjMHQr2pWhtwUCjJ2p7qxH-SpjVAO0Efxmth1hkxESxRakCKDbRRgrA/exec",
        formData
      );
    } catch (error) {
      throw error;
    }
  };

  // счетчик символов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCounters((prevCounters) => ({
      ...prevCounters,
      [name]: value.length,
    }));
  };

  return (
    <div className={style.master}>
      <img className={style.flower_blue} src={flower_blue} alt="flower_blue" />
      <img
        className={style.flower_green}
        src={flower_green}
        alt="flower_green"
      />

      <div className={`${style.main} container`} id='mentor_questions'>
        <p className={style.title}>Есть вопросы?</p>
        <p className={style.description}>
          Заполните форму обратной связи и наш менеджер позвонит вам
        </p>
        <form onSubmit={handleSubmit(onSubmit)} name="submit-to-google-sheet">
          <div className={style.content}>
            <input
              {...register("Name", {
                required: "Поле обязательно для заполнения",
              })}
              name="Name"
              placeholder="Ваше имя"
              className={style.input}
            />
            <div className={style.error}>
              {errors.Name && errors.Name.message && (
                <p>{errors.Name.message}</p>
              )}
            </div>
          </div>
          <div className={style.two_inputs}>
            <div className={style.content}>
              <input
                {...register("Phone", {
                  required: "Поле обязательно для заполнения",
                  pattern: {
                    value: /^[\d\+][\d\(\)\ -]{4,14}\d$/,
                    message: "Введите корректный номер телефона"
                  }
                })}
                name="Phone"
                placeholder="Номер телефона"
                className={style.input}
                required
              />
              <div className={style.error}>
                {errors.Phone && errors.Phone.message === "required" && (
                  <p>{errors.Phone.message}</p>
                )}
                {errors.Phone && errors.Phone.message !== "required" && (
                  <p>{errors.Phone.message}</p>
                )}
              </div>
            </div>
            <div className={style.content}>
              <input
                {...register("Email", {
                  required: "Поле обязательно для заполнения",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Введите корректный email"
                  }
                })}
                name="Email"
                placeholder="E-mail"
                className={style.input}
                required
              />
              <div className={style.error}>
                {errors.Email && errors.Email.message === "required" && (
                  <p>{errors.Email.message}</p>
                )}
                {errors.Email && errors.Email.message !== "required" && (
                  <p>{errors.Email.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className={style.comment}>
            <textarea
              {...register("Comment", {
                maxLength: {
                  value: 500,
                  message:
                    "Превышена максимальная длина символов (500). Пожалуйста, сократите текст",
                },
              })}
              name="Comment"
              className={style.textarea}
              rows="4"
              placeholder="Комментарий"
              onChange={handleInputChange}
            />
            <div className={style.error}>
              {errors.Comment && errors.Comment.message && (
                <p>{errors.Comment.message}</p>
              )}
            </div>
            <p className={style.count}>{counters.Comment}/500</p>
          </div>
          <p className={style.accept}>
            Нажимая кнопку “Отправить” вы соглашаетесь с условиями <Link to="/policy.pdf" target="_blank">политики конфедециальности</Link>
          </p>
          <button className={style.button_send} type="submit">
            Отправить
          </button>
        </form>
      </div>

      <ModalWindow
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
}
