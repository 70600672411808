import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  events: [],
  loading: false,
};

// функция, отвечающая за получение всех менторов
export const getAllEvents = createAsyncThunk(
  "event/getAllEvents",
  async () => {
    try {
      const { data } = await axios.get("/events");
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // получение всех менторов
      .addCase(getAllEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload.events;
      })
      .addCase(getAllEvents.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default eventSlice.reducer;
