import React from 'react'
import ScrollNavigalion from '../../common/ScrollNavigation'

const navItems = [
    { id: 'main_banner', label: 'От Корней к Кроне', offset: 50 },
    { id: 'main_team', label: 'О проекте', offset: -10 },
    { id: 'main_products', label: 'Продукция', offset: 50 },
    { id: 'main_mentorship', label: 'Наставничество и профориентация', offset: 50 },
    { id: 'main_new', label: 'Новости', offset: -10 },
    { id: 'main_events', label: 'Мероприятия', offset: -10 },
  ];

const ScrollNavMain = () => {

    const spy = true;
    const smooth = true;
    const duration = 500;

  return (
    <ScrollNavigalion navItems={navItems} spy={spy} smooth={smooth} duration={duration} />
  )
}

export default ScrollNavMain