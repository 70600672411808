import React from "react";
import style from "./index.module.css";
import './customTeamWrapper.css'
import Carousel from "react-multi-carousel";
import presentation from "../../../assets/images/team/slider/presentation.jpg";
import game_process1 from "../../../assets/images/team/slider/game_process1.jpg";
import game_process2 from "../../../assets/images/team/slider/game_process2.jpg";
import game_process3 from "../../../assets/images/team/slider/game_process3.jpg";
import game from "../../../assets/images/team/slider/game.jpg";
import game_process4 from "../../../assets/images/team/slider/game_process4.jpg";


const PhotoSlider = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={`${style.main} container`}>
        <Carousel
          className={style.carousel}
          showDots={true}
          responsive={responsive}
        >
          <img className={style.photo} src={presentation} alt="presentation" />
          <img className={style.photo} src={game_process1} alt="game_process1" />
          <img className={style.photo} src={game_process2} alt="game_process2" />
          <img className={style.photo} src={game_process3} alt="game_process3" />
          <img className={style.photo} src={game} alt="game" />
          <img className={style.photo} src={game_process4} alt="game_process4" />
        </Carousel>
      </div>
  );
};

export default PhotoSlider;
