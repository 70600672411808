import React from 'react'
import { useNavigate } from 'react-router-dom'

import style from './index.module.css'

const GoBack = () => {
    const navigate = useNavigate();

    const goBack = () => {
    if (document.referrer.indexOf('yourdomain.com') >= 0) {
        navigate('/', {replace: true})
    } else {
        navigate(-1);
    }
  };

    return (
    <div className='container'>
        <div className={style.go_back}>
            <button onClick={goBack}>Назад</button>
        </div>        
    </div>
    )
}

export default GoBack