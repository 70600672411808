import React from "react";
import style from "./index.module.css";
import arrow3 from "../../../assets/images/team/arrow3.png";
import arrow2 from "../../../assets/images/team/arrow2.png";

const Team = () => {
  return (
    <div className={style.main} id='team_team'>
      <div className="container">
        <h2 className={style.title}>Команда проекта</h2>
        <div className={style.item}>
          <div className={style.paragraph1}>
            <img className={style.arrow3} src={arrow3} alt="arrow3" />
            <div className={style.description}>
              <p>
                Вроде на первый взгляд,
                <span className={`${style.orange} ${style.orange_1}`}> зачем нужна</span>
                <span className={`${style.orange} ${style.orange_2}`}>команда,</span>
                если:
              </p>
              <p>— идея моя; </p>
              <p>
                — сама идеально понимаю суть своего проекта и как никто другой
                заинтересована в его реализации;
              </p>{" "}
              <p>
                — зачем делиться с кем‑то информацией и потенциальными доходами.
              </p>
            </div>
          </div>
          <div className={style.paragraph2}>
            <div className={style.description}>
              <p>
                Но, поверьте,{" "}
                <span className={`${style.green} ${style.green_1}`}>
                  все получается намного
                </span>
                <span className={`${style.green} ${style.green_2}`}>
                  лучше,
                </span>
                если:
              </p>
              <p>— есть от кого услышать критику; </p>
              <p>— проверить свои идеи на актуальность;</p>
              <p>— можно разделить исполнение каких‑либо функций;</p>
              <p>
                — можно использовать творческий потенциал, каждый представляет
                что‑то своё, что рождает больше идей;
              </p>
              <p>
                — <span className={`${style.orange} ${style.orange_3}`}>есть поддержка,</span> когда
                опускаются руки и кажется что все напрасно;
              </p>{" "}
              <p>
                — понимаешь, что
                <span className={`${style.blue} ${style.blue_1}`}>
                  вместе сильнее
                </span>
                <span className={`${style.blue} ${style.blue_2}`}>
                  и эффективнее;
                </span>
              </p>
              <p>
                — начинаешь осознавать, что к тебе присоединяются
                такие же как ты, значить ты 
                <span className={`${style.blue} ${style.blue_3}`}>идёшь в правильном </span>
                <span className={`${style.blue} ${style.blue_4}`}>направлении,</span> и уже никак
                не имеешь право их разочаровать, а должна, просто{" "}
                <span className={`${style.orange} ${style.orange_4}`}>обязана, двигаться </span>
                <span className={`${style.orange} ${style.orange_5}`}>вперёд.</span>
              </p>
            </div>
            <img className={style.arrow2} src={arrow2} alt="arrow2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
