import React from "react";
import style from "./index.module.css";

const Achievements = () => {
  return (
    <div className={style.main} id='team_achievements'>
      <div className="container">
        <h2 className={style.title}>Наши достижения</h2>
        <ul className={style.list}>
          <li>
            Созданы и проведены профориентационные квизы «Эколог.
            Ожидание/Реальность» в ННГАСУ, охватив фактически все курсы
            направления Экология и природопользование -{" "}
            <span className={style.bold}>более 50 участников!!!</span>
          </li>
          <li>
            Создана и проведена игра «Эколиния» с участием представителей
            природоохранной государственной власти и организаций.
          </li>
          <li>
            Проведены деловые игры для профессиональных экологов в рамках
            корпоративных мероприятий (до 80 участников).
          </li>
          <li>Создание и проведение онлайн игры.</li>
        </ul>
      </div>
    </div>
  );
};

export default Achievements;
