import { configureStore } from "@reduxjs/toolkit";
import mentorSlice from "./mentor/mentorSlice";
import gameSlice from "./game/gameSlice";
import eventSlice from "./event/eventSlice";
import newsSlice from "./news/newsSlice";
import blogSlice from "./blog/blogSlice";

export const store = configureStore({
  reducer: {
    mentor: mentorSlice,
    game: gameSlice,
    event: eventSlice,
    news: newsSlice,
    blog: blogSlice,
  },
});
