import React from "react";
import style from "./index.module.css";
import gratitude1 from "../../../assets/images/team/gratitude1.jpg";
import gratitude2 from "../../../assets/images/team/gratitude2.jpg";


const Diplomas = () => {
  return (
    <div className={style.main}>
      <div className="container">
        <div className={style.photos}>
          <img src={gratitude1} alt="gratitude1" />
          <img src={gratitude2} alt="gratitude2" />
        </div>
      </div>
    </div>
  );
};

export default Diplomas;
