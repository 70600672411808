import React from "react";
import { Link } from 'react-router-dom'

import MenuItem from '../MenuItem'
import Logo from "../../Logo";
import BlockContact from '../BlockContact'

import style from "./index.module.css";

const Footer = () => {
  return (
    <section className={style.footer}>
      <div className="container">
        <div className={style.footer_list}>
          <div className={style.footer_top}>
            <div className={style.footer_logo}>
              <Logo/>
            </div>            
            <ul className={style.menu}>
              
              {/* Компонент пунктов меню */}
              <MenuItem />

            </ul>
            <div className={style.footer_block__contact}>
              <BlockContact />
            </div>
          </div>
          <div className={style.footer_bottom}>
            <Link className={style.copyright} to="https://lad-academy.ru/main" target="_blank">
              Ⓒ <span>Created by Lad academy</span>
            </Link>
            <Link className={style.privacy} to="/policy.pdf" target="_blank">
              <span>Политика конфиденциальности</span>
            </Link>
            <span>Все права защищены, 2023</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
