import React from "react";
import style from "./index.module.css";
import girl from "../../../assets/images/mentors/girl.png";

export default function MentorsDescription() {
  return (
    <div className={style.main}>
      <img className={style.photo_girl} src={girl} alt="girl" />
      <div className={style.description_with_text}>
        <span className={style.text}>
          Наши эксперты — специалисты в роли наставников помогут Вам в вопросах
          связанных с профессией эколога, темами научных исследований,
          актуальности получаемых знаний и их практического применения.
        </span>
      </div>
    </div>
  );
}
