import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  news: [],
  loading: false,
};

// функция, отвечающая за получение всех новостей
export const getAllNews = createAsyncThunk("news/getAllNews", async () => {
  try {
    const { data } = await axios.get("/news");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // получение всех новостей
      .addCase(getAllNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload.news;
      })
      .addCase(getAllNews.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default newsSlice.reducer;
