import React, { useState } from 'react'

import style from './index.module.css'
import {AiOutlineMenu} from 'react-icons/ai'
import { ReactComponent as IconClose} from '../../../assets/images/mobile-close.svg'
import MenuItem from '../MenuItem'
import Logo from '../../Logo'
import BlockContact from '../BlockContact'

const Header = () => {
  const [nav, setNav] = useState(false);
  return (
    <header className={style.header}>
      <div className="container">
        <div className={style.row}>          
          <Logo />
          <ul className={nav ? [style.menu, style.active].join(' ') : [style.menu]}>
            
            <MenuItem />           
            <div className={style.block_contact}>
              <BlockContact />
            </div>
            
          </ul>

          <div onClick={() => setNav(!nav)} className={style.mobile_btn}>
            {nav ? <IconClose size={30}/> : <AiOutlineMenu size={30}/>}           
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header