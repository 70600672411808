import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  games: [],
  loading: false,
  error: null,
  params: {
    minPrice: null,
    maxPrice: null,
    format: [],
    difficulty: [],
  },
};

// функция, отвечающая за получение всех игр
export const getAllGames = createAsyncThunk("game/getAllGames", async () => {
  try {
    const { data } = await axios.get("/games");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    // функция, обновляет параметры игр в состоянии приложения
    setGameParams(state, action) {
      const { minPrice, maxPrice, format, difficulty } = action.payload;
      state.params.minPrice = minPrice;
      state.params.maxPrice = maxPrice;
      state.params.format = format;
      state.params.difficulty = difficulty;
    },
  },
  extraReducers: (builder) => {
    builder
      // получение всех игр
      .addCase(getAllGames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.loading = false;
        state.games = action.payload.games;
      })
      .addCase(getAllGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// функция, отвечающая за отображения игр по заданным параметрам
export const fetchGames = () => async (dispatch, getState) => {
  dispatch(getAllGames.pending());

  try {
    const { params } = getState().game;
    const { minPrice, maxPrice, format, difficulty } = params;

    const queryParams = {};

    if (minPrice !== null && minPrice !== undefined) {
      queryParams.minPrice = minPrice;
    }

    if (maxPrice !== null && maxPrice !== undefined) {
      queryParams.maxPrice = maxPrice;
    }

    if (format && format.length > 0) {
      queryParams.format = format;
    }

    if (difficulty && difficulty.length > 0) {
      queryParams.difficulty = difficulty;
    }

    const response = await axios.get("/games", { params: queryParams });
    dispatch(getAllGames.fulfilled(response.data));
  } catch (error) {
    dispatch(getAllGames.rejected(error.message));
  }
};

// функция, отображает объект с типом(type) и полезной нагрузкой(payload), содержащей переданные значения.
export const setGameParams = (minPrice, maxPrice, format, difficulty) => ({
  type: "game/setGameParams",
  payload: {
    minPrice,
    maxPrice,
    format,
    difficulty,
  },
});

export default gameSlice.reducer;
