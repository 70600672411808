import React from 'react';
import { Link } from 'react-router-dom'

import woman from '../../../assets/images/main/team/team-woman.png';
import team_planet from '../../../assets/images/main/team/team-planet.png';
import team_drawing from '../../../assets/images/main/team/team-drawing.png';
import { ReactComponent as IconArrow } from '../../../assets/images/main/arrow.svg'

import style from './index.module.css';


const Team = () => {
  return (
    <div className={style.team} id='main_team'>
        <div className="container">
            <h2 className={style.title}>О проекте</h2>
            <div className={style.team_row}>
                <img className={style.team_img__main} src={woman} alt="Woman" />
                <div className={style.team_right}>
                    <span className={style.text_title}>Брусникина Надежда Ивановна</span>

                    <p className={style.text_subtitle}>Автор проекта «От Корней к кроне», практикующий эколог, внештатный эксперт Росприроднадзора по ПФО.</p>

                    <p className={style.part_text}>Проект «От корней к кроне» направлен на популяризацию профессии эколог через игровой формат.</p>

                    <div className={style.part_text}>
                        <span>Поможем: </span>
                        <ul>
                            <li> — прокачать профессиональные навыки и знания;</li>
                            <li> — повысить уровень экограмотности;</li>
                            <li> — выявить инициативных и профессиональных сотрудников;</li>
                            <li> — подготовить молодые кадры.</li>
                        </ul>
                        
                    </div>

                    <p className={style.part_text__last}>Тематическое наполнение каждой игры формируется конкретно под игровую аудиторию, с учётом уровня подготовленности, тематической заинтересованности и цели самой игры. В процессе разработки игры учитывается ваш личный запрос, пожелания обсуждаются на всех этапах до конечного результата.</p>
                    
                    <div className={style.team_link}>
                        <Link to='/team' className='btn'>
                            Подробнее
                            <IconArrow className={style.arrow} />
                        </Link>
                    </div>                    
                    
                    <div className={style.box_images}>
                        <img width={195} height={250} src={team_planet} alt="team_planet" /> 
                        <img width={335} height={313} src={team_drawing} alt="team_drawing" />
                    </div>                       
                </div>
            </div>
        </div>
    </div>
  )
}

export default Team