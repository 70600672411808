import React, { useEffect } from "react";
import branch_right from "../../../assets/images/main/new/branch_right.png";
import style from "./index.module.css";

const New = () => {
  useEffect(() => {
    window.VK.Widgets.Group(
      "vk_groups",
      {
        mode: 4,
        width: "auto",
        height: "800",
        color2: "000000",
        color3: "5181B8",
      },
      217632014
    );
  }, []);

  return (
    <section className={style.new} id="main_new">
      <div className="container">
        <div className={style.new_block}>
          <h2>НОВОСТИ</h2>
          <div className={style.new_list}>
            <div id="vk_groups"></div>
          </div>
          <img className={style.img_branch_right} src={branch_right} alt="" />
        </div>
      </div>
    </section>
  );
};

export default New;
