import React, { useEffect } from "react";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../../redux/event/eventSlice";
import EventCard from "./EventCard";

export default function Events() {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  return (
    <div className={style.main} id="main_events">
      <div className="container">
        <h2 className={style.title}>МЕРОПРИЯТИЯ</h2>
        <div className={style.event_cards}>
          {events?.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      </div>
    </div>
  );
}
