import React from "react";
import style from "./index.module.css";
import players_second from "../../../assets/images/mentors/players_second.jpg";
import icon1 from "../../../assets/images/team/person_icons/icon1.png";
import icon2 from "../../../assets/images/team/person_icons/icon2.png";
import icon3 from "../../../assets/images/team/person_icons/icon3.png";
import icon4 from "../../../assets/images/team/person_icons/icon4.png";
import icon5 from "../../../assets/images/team/person_icons/icon5.png";
import icon6 from "../../../assets/images/team/person_icons/icon6.png";

const BusinessGames = () => {
  return (
    <div className={style.main} id='team_business__games'>
      <div className="container">
        <h2 className={style.title}>Деловые экологические игры</h2>
        <h3 className={style.title_small}>
          Экологическая профориентация в формате деловой игры
        </h3>
        <div className={style.description}>
          <div className={style.paragraphs}>
            <div className={style.text_left}>
              <p className={style.text}>
                Тематическое наполнение каждой игры организуется конкретно
                под аудиторию, с учётом уровня подготовленности и тематической
                заинтересованности.
              </p>
              <p className={style.text}>
                Каждая игра создаётся под аудиторию один раз для анализа
                определённого круга проблем и конкретной тематической
                деятельности.
              </p>
            </div>
            <div className={style.photos_right}>
              <div className={style.rectangle_small}></div>
              <img
                className={style.photo_players_second}
                src={players_second}
                alt="players_second"
              />
            </div>            
          </div>          
          <ul className={style.game_types}>
              <li>Настольная</li>
              <li>Настольно-интерактивная</li>
              <li>Коммуникативная </li>
              <li>Онлайн</li>
              <li>Командная</li>
            </ul>
        </div>
        
        <h2 className={style.title_second}>Кто может участвовать в проекте?</h2>
        
        <ul className={style.for_who}>
          <li>
            <img className={style.icon} src={icon1} alt="icon1" />
            <p className={style.profession}>Профессиональные экологи</p>
          </li>
          <li>
            <img className={style.icon} src={icon2} alt="icon2" />
            <p className={style.profession}>Студенты профильных ВУЗов</p>
          </li>
          <li>
            <img className={style.icon} src={icon3} alt="icon3" />
            <p className={style.profession}>Ученики старших классов</p>
          </li>
          <li>
            <img className={style.icon} src={icon4} alt="icon4" />
            <p className={style.profession}>Интересующиеся темой экологии</p>
          </li>
          <li>
            <img className={style.icon} src={icon5} alt="icon5" />
            <p className={style.profession}>Представители власти и бизнеса</p>
          </li>
          <li>
            <img className={style.icon} src={icon6} alt="icon6" />
            <p className={style.profession}>Преподаватели</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BusinessGames;
