import React from "react";
import style from "./index.module.css";
import { Link } from "react-router-dom";

export default function MentorCard({ mentor }) {
  return (
    <div className={style.main}>
      <img
        className={style.photo}
        src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${mentor.previewImg}`}
        alt="img"
      />
      <div className={style.role}>
        {mentor.role === "Другое" ? mentor.otherRole : mentor.role}
      </div>
      <div className={style.text_bold}>{mentor.fullName}</div>
      <div className={style.text_thin}>{mentor.jobTitle}</div>
      <p className={style.text_bold}>Направления:</p>
      <div className={style.text_thin}>{mentor.direction}</div>
      <div className={style.text_thin_exp}>{mentor.experience}</div>
      <Link className={style.link_more} to={`/mentor/${mentor.id}`}>
        Подробнее
      </Link>
    </div>
  );
}
