import React from "react";
import style from "./index.module.css";
import Breadcrumbs from "../../Breadcrumbs";
import flower from "../../../assets/images/main/product/flower-right.png";
import flower_green from "../../../assets/images/mentors/flower_green.png";
import GoBack from "../../../modules/GoBack";
import Carousel from "react-multi-carousel";
import Arrow_left from "../../../assets/images/news/Arrow_left.svg";
import Arrow_right from "../../../assets/images/news/Arrow_right.svg";

export default function NewsCardPage({ news }) {
  const imageArray = () => {
    const imageArray = news.imgIds.filter((id) => id !== news.previewImg);
    imageArray.unshift(news.previewImg);
    return imageArray;
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 800 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        aria-label="Go to previous slide"
        className={style.Arrow_left}
        type="button"
        onClick={onClick}
      >
        {" "}
        <img src={Arrow_left} alt="Arrow_left" />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        aria-label="Go to next slide"
        className={style.Arrow_right}
        type="button"
        onClick={onClick}
      >
        {" "}
        <img src={Arrow_right} alt="Arrow_right" />
      </button>
    );
  };



  return (
    <div className={style.main}>
      <div className="container">
        <img className={style.flower_right} src={flower} alt="flower_right" />
        <img
          className={style.flower_green}
          src={flower_green}
          alt="flower_green"
        />
        <div>
          <Breadcrumbs pageName={news.title} />
          <GoBack />
          <h1 className={style.title}>{news.title}</h1>
          <p>{news.smallDescription}</p>
          <div className={style.description}>
            <p className={style.subtitle}>{news.firstSubtitle}</p>
            <p>{news.firstSubtitleDescription}</p>

            {news.secondSubtitle && (
              <>
                <p className={style.subtitle}>{news.secondSubtitle}</p>
              </>
            )}
            {news.secondSubtitleDescription && (
              <>
                <p className={style.text}>{news.secondSubtitleDescription}</p>
              </>
            )}
            {news.thirdSubtitle && (
              <>
                <p className={style.subtitle}>{news.thirdSubtitle}</p>
              </>
            )}
            {news.thirdSubtitleDescription && (
              <>
                <p className={style.text}>{news.thirdSubtitleDescription}</p>
              </>
            )}
            {news.fourthSubtitle && (
              <>
                <p className={style.subtitle}>{news.fourthSubtitle}</p>
              </>
            )}
            {news.fourthSubtitleDescription && (
              <>
                <p className={style.text}>{news.fourthSubtitleDescription}</p>
              </>
            )}
            {news.fifthSubtitle && (
              <>
                <p className={style.subtitle}>{news.fifthSubtitle}</p>
              </>
            )}
            {news.fifthSubtitleDescription && (
              <>
                <p className={style.text}>{news.fifthSubtitleDescription}</p>
              </>
            )}
          </div>

          <div className={style.carousel_main}>
            <Carousel
              className={style.carousel}
              showDots={true}
              responsive={responsive}
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
            >
              {imageArray().map((imgId) => (
                <div className={style.carousel_photo} key={imgId}>
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${imgId}`}
                    alt="carousel-img"
                    className={style.photo}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
