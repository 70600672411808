import React from "react";
import style from "./index.module.css";
import players from "../../../assets/images/mentors/players.jpg";
import players_second from "../../../assets/images/mentors/players_second.jpg";

export default function CareerGuidanceDescription() {
  return (
    <div className={style.main}>
      <div className="container">
        <div id="mentor_career__guidance">
          <p className={style.title}>Что такое Экологическая профориентация?</p>
          <div className={style.first_description}>
            <div className={style.photos_left}>
              <div className={style.rectangle_large}></div>
              <img
                className={style.photo_players}
                src={players}
                alt="players"
              />
            </div>
            <div className={style.paragraphs}>
              <p className={style.text}>
                Экологическая профориентация — новое направление
                в професиональной квалификации(ориентации) молодёжи
                и специалистов, вклющающее в себя систему форм, методов
                и мероприятий,направленных на формирование самоопределения,
                целенаправленного и профессионального выбора и посторении
                карьеры в сфере экологии.
              </p>
              <p className={style.text}>
                Взаимодействие профессиональных экологов, студентов и школьников
                старшего звена в формате деловой игры позволит максимально
                эффективно сформировать понимание профессии среди молодёжи,
                а также поднять уровень знаний в сфере Промышленной экологии.
              </p>
            </div>
          </div>
        </div>
        <div id="mentor_business__game">
          <p className={style.title}>
            Экологическая профориентация в формате деловой игры
          </p>
          <div className={style.second_description}>
            <div className={style.paragraphs}>
              <p className={style.text}>
                Тематическое наполнение каждой игры организуется конкретно
                под аудиторию, с учётом уровня подготовленности и тематической
                заинтересованности.
              </p>
              <p className={style.text}>
                Каждая игра создаётся под аудиторию один раз для анализа
                определённого круга проблем и конкретной тематической
                деятельности.
              </p>
              <ul className={style.game_types}>
                <li>Настольная</li>
                <li>Настольно-интерактивная</li>
                <li>Коммуникативная </li>
                <li>Онлайн</li>
                <li>Командная</li>
              </ul>
            </div>
            <div className={style.photos_right}>
              <div className={style.rectangle_small}></div>
              <img
                className={style.photo_players_second}
                src={players_second}
                alt="players_second"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
