import React from "react";
import style from "./index.module.css";
import Person from "../Person";
import ecolog from "../../../assets/images/mentors/ecolog.svg";
import student from "../../../assets/images/mentors/student.svg";
import flower from "../../../assets/images/main/product/flower-right.png";
import GoBack from "../../../modules/GoBack";

export default function ForWho() {
  return (
    <div id='mentor_for__who'>
      <img className={style.flower_right} src={flower} alt="flower_right" />
      <div className={`${style.main} container`}>
        <GoBack />
        <h1 className={style.head_title}>Наставничество <br></br>и профориентация</h1>
        <p className={style.title}>Кому подходит наставничество ?</p>
        <div className={style.persons}>
          <Person person={student} text="Студентам профессиональных вузов" />
          <Person person={ecolog} text="Начинающим экологам" />
        </div>
      </div>
    </div>
  );
}
