import React from 'react'
import ScrollNavigalion from '../../common/ScrollNavigation'

const navItems = [
    { id: 'mentor_for__who', label: 'Кому подходит?', offset: 100 },
    { id: 'mentor_mentors', label: 'Наставники', offset: -10 },
    { id: 'mentor_career__guidance', label: 'Что такое экологическая профориентация?', offset: -10 },
    { id: 'mentor_business__game', label: 'Деловая игра', offset: -10 },
    { id: 'mentor_questions', label: 'Есть вопросы?', offset: -10 },
  ];

const ScrollNavMentor = () => {

    const spy = true;
    const smooth = true;
    const duration = 500;

  return (
    <ScrollNavigalion navItems={navItems} spy={spy} smooth={smooth} duration={duration} />
  )
}

export default ScrollNavMentor