import React, { useEffect } from "react";
import style from "./index.module.css";
import MentorsDescription from "../MentorsDescription";
import { useDispatch, useSelector } from "react-redux";
import { getAllMentors } from "../../../redux/mentor/mentorSlice";
import MentorCard from "../MentorCard";

export default function MentorsCatalog() {
  const dispatch = useDispatch();
  const mentors = useSelector((state) => state.mentor.mentors);

  useEffect(() => {
    dispatch(getAllMentors());
  }, [dispatch]);

  return (
    <div className={`${style.main} container`} id='mentor_mentors'>
      <p className={style.title}>Наставники проекта “От Корней к Кроне”</p>
      <MentorsDescription />

      <div className={style.mentor_cards}>
        {mentors
          ?.filter((mentor) => mentor.role !== "Другое")
          .map((mentor) => (
            <MentorCard key={mentor.id} mentor={mentor} />
          ))}
      </div>
    </div>
  );
}
