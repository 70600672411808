import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import ForWho from "../components/MentorWrapper/ForWho";
import MentorsCatalog from "../components/MentorWrapper/MentorsCatalog";
import CareerGuidanceDescription from "../components/MentorWrapper/CareerGuidanceDescription";
import FeedbackForm from "../components/MentorWrapper/FeedbackForm";
import ScrollNavMentor from "../components/MentorWrapper/ScrollNavMentor";

const MentorPage = () => {
  return (
    <div>
      <Breadcrumbs />
      <ForWho />
      <MentorsCatalog />
      <CareerGuidanceDescription />
      <FeedbackForm />
      <ScrollNavMentor />
    </div>
  );
};

export default MentorPage;
