import React from "react";
import style from "./index.module.css";

export default function Person(props) {
  return (
    <div className={style.location}>
      <img
        className={style.image}
        width={80}
        height={80}
        src={props.person}
        alt="person"
      />
      <div className={style.dialog_window}>
        <span className={style.text_inside_dialog}>{props.text}</span>
      </div>
    </div>
  );
}
