import React from 'react'

import style from './index.module.css'

const CatalogTitle = () => {
  return (
    <div>
        <div className={style.catalog}>
            <div className='container'>
                <div className={style.catalog_content}>
                    <h2>Продукция</h2>
                </div> 
            </div>
        </div>        
    </div>
  )
}

export default CatalogTitle