import React from 'react'

import productBanner1 from '../../../../assets/images/main/product/product_banner_1.jpg'
import productBanner2 from '../../../../assets/images/main/product/product_banner_2.jpg'
import arrowRounded from '../../../../assets/images/main/product/arrow-rounded.svg'
import curlBig from '../../../../assets/images/main/product/curl-big.svg'
import curlLittle from '../../../../assets/images/main/product/curl-little.svg'

import { ReactComponent as BackgroundTextGame } from '../../../../assets/images/main/product/vectors/background-text-game.svg'

import style from './index.module.css'


const ProductsBanner = () => {
  return (

    <div className={style.products_banners}>
        <div className='container'>

            <div className={style.text_top}>
                <p>
                    <span className={style.text_color__1}>Игровые форматы
                    </span> 
                    могут быть
                    <span className={style.text_color__2}> разнообразны:</span>
                </p>
                <ul>
                    <li> — настольные;</li>
                    <li> — интерактивные;</li>
                    <li> — онлайн;</li>
                    <li> — квизы, тимбилдинги.</li>
                </ul>
                <p> Каждый, из которых 
                    <span className={style.text_color__3}> является авторским</span>
                    <span className={style.text_color__4}> интеллектуальным продуктом,</span> использование и применение которого возможно только по соглашению сторон.</p>
                <img className={style.arrow_rounded} src={arrowRounded} alt="стрелка закругленная" />
            </div >

            <div className={style.banner_top}>
                <img className={style.curl_big} src={curlBig} alt="стрекла большая" />
                <img className={style.banner_1} src={productBanner1} alt="banner_1" />
                <div className={style.banner_text}>
                    <h3>Настольные игры</h3>
                    <p>
                        <span className={style.text_color__1}>Серия игр «ЭкоПрофи»:</span> два варианта в зависимости от уровня подготовки:</p>
                    <p><strong>ЭкоПрофи</strong> — для практикующих экологов.</p>
                    <p><strong>ЭкоПрофи лайт</strong> — для повышения экограмотности новичков в профессии эколога и рядовых сотрудников.</p>
                    <p>
                        <span className={style.text_color__2}>Играть можно в любой аудитории.</span>
                    </p>
                    <p>Не требуют дополнительного технического оборудования.</p>
                </div>
            </div>

            <div className={style.banner_bottom}>
                <div className={style.banner_text}>
                    <h3>Интерактивные и онлайн</h3>
                    <ul>
                        <li> — квизы, тимбилдинги;</li>
                        <li> — варианты настольных игр + вывод вопросов и ответов на экран;</li>
                        <li> — командная онлайн игра.</li>
                    </ul>
                    <p>
                        <span className={style.text_color__1}>Игровые варианты разнообразны.</span>
                    </p>
                    <p>Вопросы формируются под аудиторию и уровень знаний.</p>
                    <p>
                        <span className={style.text_color__2}>Участие ведущего обговаривается</span>
                        <span className={style.text_color__3}>отдельно.</span>
                    </p>
                </div>
                <img className={style.banner_2} src={productBanner2} alt="banner_2" />
            </div>

            <div className={style.text_bottom}>
                <p>Мы 
                    <span className={style.text_color__1}> можем разработать</span> формат игры 
                    <span className={style.text_color__2}> индивидуально </span>
                    под Вашу аудиторию и тематику. Также можно рассмотреть смешение уже разработанных форматов.
                </p>                
            </div>
            
            <div className={style.games_stock}>
                <BackgroundTextGame className={style.background_text_game}  alt="фон текста - игры в наличии" />
                <span>Игры в наличии</span>
                <img className={style.curl_little} src={curlLittle} alt="стрелка маленькая" />
            </div>
        </div>
    </div>
    
  )
}

export default ProductsBanner