import React from 'react'
import { Link } from 'react-router-dom'

import vk from "../../../assets/images/vk.svg";

import style from "./index.module.css";

const BlockContact = () => {
  return (
    <div className={style.social}>
        <Link to="tel:+79200220602">8 920 022 06 02</Link>
        <Link to="mailto:bru211@yandex.ru">bru211@yandex.ru</Link>
        <Link className={style.vk} to="https://vk.com/bruneco" target="_blank">
            <img src={vk} alt="иконка VK" />
            <span> Мы в социальных сетях</span>
        </Link>
    </div>
  )
}

export default BlockContact