import React, { useEffect, useState } from "react";
import NewsCardPage from "../components/NewsCardWrapper/NewsCardPage";
import { useParams } from "react-router-dom";
import axios from "../utils/axios";

const NewsSinglePage = () => {
  const [news, setNews] = useState(null);
  const params = useParams();

  useEffect(() => {
    const fetchNews = async () => {
      const { data } = await axios.get(`/news/${params.id}`);
      setNews(data);
    };

    fetchNews();
  }, [params.id]);

  return <div>{news && <NewsCardPage news={news} />}</div>;
};

export { NewsSinglePage };
