import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";

import MainPage from "./pages/MainPage";
import TeamPage from "./pages/TeamPage";
import MentorPage from "./pages/MentorPage";
import NewsPage from "./pages/NewsPage";
import CatalogPage from "./pages/CatalogPage";
import ProductPage from "./pages/ProductPage";
import NotFoundPage from "./pages/NotFoundPage";

import PageWrapper from "./components/common/PageWrapper";

import { MentorSinglePage } from "./pages/MentorSinglePage";
import { NewsSinglePage } from "./pages/NewsSinglePage";
import ScrollToTop from "./ScrollToTop";

function App() {
  const router = createBrowserRouter([
    {
      element: (
        <React.Fragment>
          <ScrollToTop />
          <PageWrapper />
        </React.Fragment>
      ),
      children: [
        {
          path: "/",
          element: <MainPage />,
        },
        {
          path: "team",
          element: <TeamPage />,
        },
        {
          path: "mentor",
          element: <MentorPage />,
        },
        {
          path: "mentor/:id",
          element: <MentorSinglePage />,
        },
        {
          path: "news",
          element: <NewsPage />,
        },
        {
          path: "news/:id",
          element: <NewsSinglePage />,
        },
        {
          path: "catalog",
          element: <CatalogPage />,
        },
        {
          path: "catalog/:id",
          element: <ProductPage />,
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </React.Fragment>
  );
}

export default App;
