import React from "react";
import { Link, useLocation } from "react-router-dom";
import style from "./index.module.css";
import { BsFillCircleFill } from "react-icons/bs";

export default function Breadcrumbs(props) {
  const location = useLocation();

  const homeLink = "/";

  const oldPageName = {
    catalog: "Продукция",
    mentor: "Наставничество и профориентация",
    team: "О проекте",
    news: "Блог",
  };

  const getCurrentLink = (array, index) => {
    return "/" + array.slice(0, index + 1).join("/");
  };

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index, array) => {
      return (
        <div key={crumb}>
          {array.length !== index + 1 ? (
            <Link to={getCurrentLink(array, index)}>
              <BsFillCircleFill className={style.point_gray_color} />
              {oldPageName[crumb]}
            </Link>
          ) : (
            <div>
              <BsFillCircleFill className={style.point_gray_color} />
              <span className={style.gray_color}>
                {props.pageName || oldPageName[crumb]}
              </span>
            </div>
          )}
        </div>
      );
    });

  return (
    <div className="container">
      <div className={style.breadcrumbs}>
        <Link to={homeLink}>Главная</Link>
        {crumbs}
      </div>
    </div>
  );
}
