import React from "react";
import style from "./index.module.css";


const WhyBruneco = () => {
  return (
    <div className={style.main} id='team_bruneco'>
      <div className="container">
        <h2 className={style.title}>Почему БруНЭКО?</h2>
        <div className={style.description}>
          <p className={style.text}>
            «Я долго думала, как же именно, можно обозначить
            мою профессиональную деятельность.
          </p>
          <p className={style.text}>
            Ответ пришёл неожиданно: <span className={style.bold}>Бру</span>
            сникина <span className={style.bold}>Н</span>адежда —{" "}
            <span className={style.bold}>ЭКО</span>лог.
          </p>
          <p className={style.text}>
            Так появился логотип — <span className={style.bold}>БруНЭКО</span>.
            Начало было положено.{" "}
          </p>
          <p className={style.text}>
            Итак, здравствуйте, я Брусникина Надежда — специалист эколог,
            внештатный эксперт Росприроднадзора по ПФО, автор проекта „От Корней
            к Кроне“.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyBruneco;
