import React, { useState } from "react";
import { Link } from "react-router-dom";

import formatterPrice from "../../../../utils/FormatterPrice";

import style from "./index.module.css";
import ModalWindow from "../../../MentorWrapper/ModalWindow";
import OrderWindow from "../OrderWindow";

const CatalogItem = ({ game }) => {
  const [showWindow, setShowWindow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeWindow = () => {
    setShowWindow(false);
  };

  const openWindow = () => {
    setShowWindow(true);
  };

  return (
    <div className={style.product}>
      <Link to={`/catalog/${game.id}`}>
        <h3 className={style.title}>{game.title}</h3>
        <img
          className={style.photo}
          src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${game.previewImg}`}
          alt="product"
        />
      </Link>
      <p className={style.description}>{game.description}</p>
      <span className={style.price}>{formatterPrice.format(game.price)}</span>
      <button
        onClick={openWindow}
        className={`${style.button} btn`}
      >
        Заказать
      </button>

      <OrderWindow
        showWindow={showWindow}
        closeWindow={closeWindow}
        setShowModal={setShowModal}
        fromWhere={game.title}
      />

      <ModalWindow
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
};

export default CatalogItem;
