import React from "react";
import style from "./index.module.css";
import GoBack from "../../../modules/GoBack";
import tree from "../../../assets/images/team/tree.png";
import Breadcrumbs from "../../Breadcrumbs";

const WhyRootsCrown = () => {
  return (
    <div className={style.main}>
      <Breadcrumbs />
      <GoBack />
      <div className="container" id='team_roots__crown'>
        <img className={style.tree} src={tree} alt="tree" />
        <h1 className={style.header}>О ПРОЕКТЕ</h1>
        <h2 className={style.title}>Почему ОТ КОРНЕЙ К КРОНЕ?</h2>
        <div className={style.description}>
          <p className={style.text}>
            <span className={style.bold}>ДЕРЕВО</span> — символ экологии, развития.
          </p>
          <p className={style.text}>
            Начало — <span className={style.bold}>КОРНИ</span>, даёт нам возможность встать на выбранный путь.
          </p>
          <p className={style.text}>
            Движение вверх — <span className={style.bold}>ПО СТВОЛУ</span> — это наше развитие, обучение, знакомство с наставниками.
          </p>
          <p className={style.text}>
            Становление профессионалами — <span className={style.bold}>КРОНА</span> — дальнейшая помощь в обучение молодых специалистов.
          </p>
          <p className={style.text}>
            В кроне присутствуют круги в цветовых гаммах: оранжевого, зеленого, серого, голубого — символизирующие разные сферы в экологии: оранжевый — выбросы, зелёный — зоны с особым использованием территории, голубой — сбросы, серый — отходы.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyRootsCrown;
