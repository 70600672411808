import React from "react";
import style from "./index.module.css";
import mill from "../../../assets/images/team/mill.png";

const Goals = () => {
  return (
    <div className={style.main} id='team_goals'>
      <div className="container">
        <div className={style.content}>
            <img className={style.mill} src={mill} alt="mill" />
            <div>
                <h2 className={style.title}>ЦЕЛИ И ЗАДАЧИ ПРОЕКТА</h2>
                <ul className={style.list}>
                  <li>
                    создание и реализация интегрированной системы экологической
                    профессиональной ориентации молодёжи;
                  </li>
                  <li>
                    популяризация профессии эколога и повышение уровня экограмотности;
                  </li>
                  <li>
                    развитие профессионального уровня, лидерских качеств у специалистов,
                    задействованных в сфере экологии.
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Goals;
