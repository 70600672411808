import React from 'react'
import { Link } from 'react-router-dom'

import img_banner from '../../../assets/images/main/banner-main.png'

import style from './index.module.css';


const Banner = () => {
  return (
    <div className={style.banner}  id="main_banner">
        <div className="container">
            <div className={style.banner_row}>
                <div className={style.banner_left}>
                    <h1>От Корней к Кроне</h1>
                    <p>Деловые экологические игры для практикующих экологов, преподавателей, студентов и школьников.</p>
                    <Link to="/catalog" className="btn" >Выбрать игру</Link>
                </div>                
                <img className={style.img_banner} src={img_banner} alt="main-banner" />                
            </div>
        </div>
    </div>
  )
}

export default Banner