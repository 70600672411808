import React from "react";
import style from "./index.module.css";
import flower_green_modal from "../../../assets/images/mentors/flower_green_modal.png";
import { ReactComponent as Close } from "../../../assets/images/mentors/close.svg";

import ReactDOM from "react-dom"
const portal2 = document.getElementById('portal')

export default function ModalWindow({ showModal, closeModal }) {
  return ReactDOM.createPortal(
    <div
      className={style.background}
      style={{ display: showModal ? "flex" : "none" }}
    >
      <div className={style.modal}>
        <p className={style.title}>Ваша заявка отправлена</p>
        <Close className={style.close} onClick={closeModal} />
        <p className={style.description}>
          Мы свяжемся с Вами в течение дня. Также информация поступит Вам на
          электронную почту.
        </p>
        <p className={style.working}>Мы работаем:</p>
        <p className={style.working_time}>пн-пт 9.00 до 18.00</p>
        <p className={style.contacts}>Наши контакты:</p>
        <p className={style.phone}>8 920 022 06 02</p>
        <p className={style.email}>bru211@yandex.ru</p>
        <img
          className={style.flower}
          src={flower_green_modal}
          alt="flower_green_modal"
        />
      </div>
    </div>,
    portal2
  );
}
