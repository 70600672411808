import React, { useState } from "react";
import style from "./index.module.css";
import ModalWindow from "../../../MentorWrapper/ModalWindow";

import AppointmentWindow from "../AppointmentWindow";

export default function EventCard({ event }) {
  const [showWindow, setShowWindow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeWindow = () => {
    setShowWindow(false);
  };

  const openWindow = () => {
    setShowWindow(true);
  };

  // Функция для получения названия месяца на русском языке
  const getMonthName = (month) => {
    const monthNames = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    return monthNames[month];
  };

  // Функция для добавления ведущего нуля к числам < 10
  const addLeadingZero = (number) => {
    return number < 10 ? `0${number}` : number;
  };

  // Преобразование строки даты в объект Date
  const eventDate = new Date(event.date);

  // Форматирование даты
  const formattedDate = `${eventDate.getDate()} ${getMonthName(
    eventDate.getMonth()
  )} ${eventDate.getFullYear()} в ${addLeadingZero(
    eventDate.getHours()
  )}:${addLeadingZero(eventDate.getMinutes())}`;

  // Текущая дата
  const currentDate = new Date();
  // Проверка, прошло ли мероприятие
  const isPastEvent = currentDate >= eventDate;

  return (
    <div className={`${style.main} ${isPastEvent ? style.pastEvent : ""}`}>
      <img
        className={style.photo}
        src={`${process.env.REACT_APP_BACKEND_URL}/api/images/${event.previewImg}`}
        alt="img"
      />

      <div className={style.text_title}>{event.title}</div>
      <div className={style.text_thin}>{event.description}</div>
      <div className={style.text_bold}>{formattedDate}</div>
      <div className={style.text_bold_place}>{event.place}</div>

      {/* Проверка условия для отображения кнопки или надписи */}
      {isPastEvent ? (
        <div className={style.pastEventLabel}>Мероприятие завершено</div>
      ) : (
        <button onClick={openWindow} className={`${style.button} btn`}>
          Записаться
        </button>
      )}

      <AppointmentWindow
        showWindow={showWindow}
        closeWindow={closeWindow}
        setShowModal={setShowModal}
        fromWhere={event.title}
      />

      <ModalWindow
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
}
