import React from "react";

import Breadcrumbs from "../components/Breadcrumbs";
import NewsItem from "../components/NewsWrapper/NewsItem";
import Blog from "../components/NewsWrapper/Blog";
import Gallary from "../components/NewsWrapper/Gallary";

const NewsPage = () => {
  return (
      <div >
        <Breadcrumbs />
        <NewsItem />
        <Blog />
        <Gallary />
      </div>
  )
};

export default NewsPage;
