import React from "react";
import style from "./index.module.css";
import survey from "../../../assets/images/team/survey.png";

const Relevance = () => {
  return (
    <div className={style.main} id='team_relevance'>
        <div className="container">
          <h2 className={style.title}>Актуальность экологической профориентации</h2>
          <div className={style.description}>
            <p className={style.text}>
              Анализ многочисленных опросов в СМИ о представлении специалиста
              эколога показывает ошибочное мнение о том, что эколог занимается
              только посадкой деревьев или спасением животных. Ответы далеки
              от объективного представления профессии.
            </p>
            <p className={style.text}>
              Экология достаточно серьёзное направление, включающее в первую очередь
              знания и опыт: правовых и экономических вопросов, основ
              технологических процессов, технологии производства и воздействии
              на экосистему
            </p>
          </div>
          <p className={style.text}>
            В ходе исследования мы провели опрос среди 20 респондентов, которые
            ответили на вопрос:{" "}
            <span className={style.bold}>
              «Знаете ли вы, что существует профессия „эколог“?»
            </span>
            . Результаты опроса отображены в диаграмме.
          </p>
          <img className={style.survey} src={survey} alt="survey" />
        </div>
    </div>
  );
};

export default Relevance;
