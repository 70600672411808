import React, { useEffect } from "react";

export default function BlogCard({ blog }) {
  const PostNumber = `vk_article_-217632014_${blog.postNumber}`;

  useEffect(() => {
    window.VK.Widgets.Article(
      `vk_article_-217632014_${blog.postNumber}`,
      `${blog.postName}`
    );
  }, []);

  return (
    <div>
      <div id={PostNumber}></div>
    </div>
  );
}
